import { combineReducers } from 'redux'
import Theme from './Theme'
import Site from './Site'
import LocalCenter from './LocalCenter'
import Alert from './Alerts'
import Manhole from './Manhole'
import SignalR from './SignalR'
import Dashboard from './Dashboard'
import SmsPhones from './SmsPhones'
import Auth from './Auth';

const reducers = combineReducers({
    theme: Theme,
    site: Site,
    localcenter: LocalCenter,
    alert: Alert,
    manhole: Manhole,
    signalr: SignalR,
    dashboard: Dashboard,
    smsPhones: SmsPhones,
    auth: Auth
});

export default reducers;