import { REQUEST_DASHBOARD, 
    REQUEST_DASHBOARD_SUCCESS, 
    REQUEST_DASHBOARD_FAILED, 
    REQUEST_DASHBOARD_LOADING,
    UPDATE_ALERT_COUNT } from '../constants/Dashboard'


export const requestDashboard = (siteid) => {
    return {
        type: REQUEST_DASHBOARD,
        siteid
    }
}

export const requestDashboardLoading = (isLoading) => {
    return {
        type: REQUEST_DASHBOARD_LOADING,
        isLoading
    }
}

export const requestDashboardSuccess = (data) => {
    return {
        type: REQUEST_DASHBOARD_SUCCESS,
        data
    }
}

export const requestDashboardFailed = (error) => {
    return {
        type: REQUEST_DASHBOARD_FAILED,
        error
    }
}

export const updateAlertCount = () => {
    return {
        type: UPDATE_ALERT_COUNT
    }
}