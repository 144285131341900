import { REQUEST_LOCALCENTER, 
    REQUEST_LOCALCENTER_SUCCESS, 
    REQUEST_LOCALCENTER_FAILED, 
    REQUEST_LOCALCENTER_LOADING, 
    
    REQUEST_LOCALCENTER_MODIFY,
    REQUEST_LOCALCENTER_MODIFY_SUCCESS,
    REQUEST_LOCALCENTER_MODIFY_FAILED,

    REQUEST_MANHOLE_MODIFY,
    REQUEST_MANHOLE_MODIFY_SUCCESS,
    REQUEST_MANHOLE_MODIFY_FAILED,
    UPDATE_MANHOLE,
    WAIT_MANHOLE_UPDATE,
    UPDATE_CONNECTION_STATUS
 } from '../constants/LocalCenter'

export const requestLocalCenter = (siteid) => {
    return {
        type: REQUEST_LOCALCENTER,
        siteid
    }
}

export const requestLocalCenterLoading = (isLoading) => {
    return {
        type: REQUEST_LOCALCENTER_LOADING,
        isLoading
    }
}

export const requestLocalCenterSuccess = (data) => {
    return {
        type: REQUEST_LOCALCENTER_SUCCESS,
        data
    }
}

export const requestLocalCenterFailed = (error) => {
    return {
        type: REQUEST_LOCALCENTER_FAILED,
        error
    }
}

export const requestLocalCenterModify = (localcenter) => {
    return {
        type: REQUEST_LOCALCENTER_MODIFY,
        localcenter
    }
}

export const requestLocalCenterModifySuccess = (localcenter) => {
    return {
        type: REQUEST_LOCALCENTER_MODIFY_SUCCESS,
        localcenter
    }
}

export const requestLocalCenterModifyFailed = (error) => {
    return {
        type: REQUEST_LOCALCENTER_MODIFY_FAILED,
        error
    }
}

export const requestManholeModify = (manhole) => {
    return {
        type: REQUEST_MANHOLE_MODIFY,
        manhole
    }
}

export const requestManholeModifySuccess = (manhole) => {
    return {
        type: REQUEST_MANHOLE_MODIFY_SUCCESS,
        manhole
    }
}

export const requestManholeModifyFailed = (error) => {
    return {
        type: REQUEST_MANHOLE_MODIFY_FAILED,
        error
    }
}

export const updateManhole = (status) => {
    return {
        type: UPDATE_MANHOLE,
        status
    }
}

export const waitManholeUpdate = (connection) => {
    return {
        type: WAIT_MANHOLE_UPDATE,
        connection
    }
}

export const updateConnectionStatus = (status) => {
    return {
        type: UPDATE_CONNECTION_STATUS,
        status
    }
}

