import React from "react";
import { Layout, Collapse } from 'antd';
import { connect } from 'react-redux';
//import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import { ClockCircleOutlined, CheckCircleOutlined, BarChartOutlined } from '@ant-design/icons';

import Clock from 'components/shared-components/Clock'

//import MenuContent from './MenuContent'

import SideNavAlerts from "views/app-views/alerts/SideNavAlerts";
import ManholePieChart from "views/app-views/charts/ManholePieChart";

const { Sider } = Layout;
const { Panel } = Collapse;

export const SideNav = ({navCollapsed, sideNavTheme }) => {
  //const props = { sideNavTheme, routeInfo , hideGroupTitle, localization}



  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : ''}`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}>
      <Scrollbars autoHide>
        <Collapse defaultActiveKey={['1', '2', '3']} >
          <Panel header={ <div><ClockCircleOutlined /> 현재시간</div>} key="1">
            <Clock />
          </Panel>
          <Panel header={<div><CheckCircleOutlined /> 전체현황</div>} key="2">
            <ManholePieChart />
          </Panel>
          <Panel header={<div> <BarChartOutlined /> 알람정보(지난 24시간)</div>} key="3">
            <SideNavAlerts />
          </Panel>
        </Collapse>
      </Scrollbars>
    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } =  theme;
  return { navCollapsed, sideNavTheme }
};

export default connect(mapStateToProps)(SideNav);
