import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Form, Input, Divider  } from 'antd'
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons"

import { requestSmsNumbers, requestSmsNumbersAdd, requestSmsNumbersRemove } from 'redux/actions/SmsPhones'

const SmsList = () => {
    const dispatch = useDispatch();
    const site = useSelector((state) => state.site.data)
    const smsNumbers = useSelector((state) => state.smsPhones.data)

    const [form] = Form.useForm()

    useEffect(() => {
		if (site) dispatch(requestSmsNumbers(site.id))
	}, [dispatch, site])

    const removeSms = (sms) => {
        dispatch(requestSmsNumbersRemove(sms.id))
    }

    const columns = [
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '전화번호',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '삭제',
            key: 'operation',
            render: (text, record) => (
                <span>
                    <Button type="link" size="small" onClick={() => removeSms(record)}>
                        <DeleteOutlined />
                    </Button>
                </span>
            ),
        },
    ]

    const onFinish = values => {
        dispatch(requestSmsNumbersAdd(site.id, {name: values.name, phone: values.phone}))
        form.setFieldsValue({
            name: '', phone: ''
          });
    }

    return (
    <>
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish} initialValues={{name: '', phone: ''}}>
            <Form.Item
                name="name"
                rules={[{ required: true, message: '이름을 입력해주세요.' }]}
            >
                <Input placeholder="이름" size="small" style={{width: 90}}/>
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[{ required: true, message: '전화번호를 입력해주세요' }]}
            >
                <Input placeholder="전화번호" size="small" style={{width: 110}}/>
            </Form.Item>
            <Form.Item shouldUpdate={true}>
                {() => (
                <Button
                    type="primary"
                    htmlType="submit"
                    size="small"
                    disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length
                    }
                >
                    <UserAddOutlined />
                </Button>
                )}
            </Form.Item>
        </Form>
        <Divider />
        <Table columns={columns} rowKey={'id'} dataSource={smsNumbers ?? []} pagination={false} size={'small'} />
        
    </>
    )
};

export default SmsList;