import { 
  DashboardOutlined,
  MonitorOutlined,
  InfoCircleOutlined,
  AlertOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
}]

const monitorNavTree = [{
  key: 'monitor',
  path: `${APP_PREFIX_PATH}/monitor`,
  title: 'sidenav.monitor',
  icon: MonitorOutlined,
  breadcrumb: false,
  submenu: []
}]

const alertNavTree = [{
  key: 'alerts',
  path: `${APP_PREFIX_PATH}/alerts`,
  title: 'sidenav.alert',
  icon: AlertOutlined,
  breadcrumb: false,
  submenu: []
}]

const deviceNavTree = [{
  key: 'devices',
  path: `${APP_PREFIX_PATH}/devices`,
  title: 'sidenav.device',
  icon: InfoCircleOutlined,
  breadcrumb: false,
  submenu: []
}]

const navigationConfig = [
  ...monitorNavTree,
  ...dashBoardNavTree,
  ...alertNavTree,
  ...deviceNavTree,
]

export default navigationConfig;
