export const REQUEST_LOCALCENTER = 'REQUEST_LOCALCENTER';
export const REQUEST_LOCALCENTER_LOADING = 'REQUEST_LOCALCENTER_LOADING';
export const REQUEST_LOCALCENTER_SUCCESS = 'REQUEST_LOCALCENTER_SUCCESS'
export const REQUEST_LOCALCENTER_FAILED = 'REQUEST_LOCALCENTER_FAILED'

export const REQUEST_LOCALCENTER_MODIFY = 'REQUEST_LOCALCENTER_MODIFY';
export const REQUEST_LOCALCENTER_MODIFY_SUCCESS = 'REQUEST_LOCALCENTER_MODIFY_SUCCESS';
export const REQUEST_LOCALCENTER_MODIFY_FAILED = 'REQUEST_LOCALCENTER_MODIFY_FAILED';

export const REQUEST_MANHOLE_MODIFY = 'REQUEST_MANHOLE_MODIFY'
export const REQUEST_MANHOLE_MODIFY_SUCCESS = 'REQUEST_MANHOLE_MODIFY_SUCCESS'
export const REQUEST_MANHOLE_MODIFY_FAILED = 'REQUEST_MANHOLE_MODIFY_FAILED'

export const WAIT_MANHOLE_UPDATE  = 'WAIT_MANHOLE_UPDATE'
export const UPDATE_MANHOLE = 'UPDATE_MANHOLE'

export const WAIT_CONNECTION_STATUS  = 'WAIT_CONNECTION_STATUS'
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS'