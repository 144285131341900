import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_SMSNUMBER, REQUEST_SMSNUMBER_ADD, REQUEST_SMSNUMBER_REMOVE 
} from '../constants/SmsPhones'
import { 
    requestSmsNumbersSuccess, 
    requestSmsNumbersFailed, 
    requestSmsNumbersAddFailed, 
    requestSmsNumbersAddSuccess, 
    requestSmsNumbersRemoveFailed,
    requestSmsNumbersRemoveSuccess
} from '../actions/SmsPhones'

import ManholeService from '../../services/ManholeService'

export function* requestSmsPhones() {
    yield takeEvery(REQUEST_SMSNUMBER, function* (action) {
        try {
            const response = yield call(ManholeService.getSmsPhones, action.siteid)
            yield put(requestSmsNumbersSuccess(response.data))
        } catch (err) {
            yield put(requestSmsNumbersFailed(err))
        }
    })
}

export function* requestSmsPhoneNumberAdd() {
    yield takeEvery(REQUEST_SMSNUMBER_ADD, function* (action) {
        try {
            const response = yield call(ManholeService.postSmsNumber, action.siteid, action.info)
            yield put(requestSmsNumbersAddSuccess(response.data))
        } catch (err) {
            yield put(requestSmsNumbersAddFailed(err))
        }
    })
}

export function* requestSmsPhoneNumberRemove() {
    yield takeEvery(REQUEST_SMSNUMBER_REMOVE, function* (action) {
        try {
            const response = yield call(ManholeService.deleteSmsPhoneNumber, action.id)
            yield put(requestSmsNumbersRemoveSuccess(response.data))
        } catch (err) {
            yield put(requestSmsNumbersRemoveFailed(err))
        }
    })
} 

export default function* rootSaga() {
    yield all([
        fork(requestSmsPhones),
        fork(requestSmsPhoneNumberAdd),
        fork(requestSmsPhoneNumberRemove)
    ])
} 