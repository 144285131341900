import { 
    REQUEST_DASHBOARD_SUCCESS, 
    REQUEST_DASHBOARD_FAILED, 
    REQUEST_DASHBOARD_LOADING,
    UPDATE_ALERT_COUNT } from '../constants/Dashboard'

const initState = {
    data: null,
    loading: false,
    err: null
}


const dashboard = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_DASHBOARD_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_DASHBOARD_SUCCESS: 
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_DASHBOARD_FAILED: 
            return {
                ...state,
                data: null,
                loading: false,
                err: action.error
            }
        case UPDATE_ALERT_COUNT: 
            return {
                ...state,
                data: { ...state.data, todayAlertCount: state.data.todayAlertCount + 1, unhandledAlertCount: state.data.unhandledAlertCount + 1 }
            }
        default:
            return state;
    }
}

export default dashboard