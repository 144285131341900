import React, { useEffect } from "react";
import { useSelector } from "react-redux"
import { notification } from 'antd'
import { useHistory } from "react-router-dom"
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {  UserOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp, faSmog, faThermometerHalf, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faCreativeCommonsSampling } from '@fortawesome/free-brands-svg-icons'

const getAlertTitle = ({type}) => {
    if (type === "SuddenRise") return "돌발수위 감지"
    else if (type === "Shock") return "개폐 감지"
    else if (type === "GasAlarm") return "가스 감지"
    else if (type === "HighTemperature") return "온도상승 감지"
    else if (type === "WaterSensorError" || type === "TempSensorError" || type === "GasSensorError" || type === "ShockSensorError" || type === "SuddenRiseSensorError") return "센서오류"
    else return ""
}

const getAlertSubTitle = (alert) => { 
    if (alert.type === "SuddenRise") return `현재수위 - ${alert.waterLevel}`
    else if (alert.type === "Shock") return "개폐 감지됨"
    else if (alert.type === "GasAlarm") return `가스레벨 - ${alert.gasLevel}`
    else if (alert.type === "HighTemperature") return `온도 - ${alert.temperature}`
    else if (alert.type === "WaterSensorError") return "수위 센서오류"
	else if (alert.type === "TempSensorError") return "온도 센서오류"
	else if (alert.type === "GasSensorError") return "가스 센서오류"
	else if (alert.type === "ShockSensorError") return "개폐 센서오류"
	else if (alert.type === "SuddenRiseSensorError") return "돌발수위 센서오류"
    else return ""
}

const getAlertIcon = ({type}) => {
    if (type === "SuddenRise") return (<FontAwesomeIcon icon={faSortAmountUp} style={{color: "blue"}}/>)
    else if (type === "Shock") return (<FontAwesomeIcon icon={faCreativeCommonsSampling} style={{color: "green"}} />)
    else if (type === "GasAlarm") return (<FontAwesomeIcon icon={faSmog} style={{color: "yellow"}} />)
    else if (type === "HighTemperature") return (<FontAwesomeIcon icon={faThermometerHalf} style={{color: "red"}}/>)
    else if (type === "WaterSensorError" || type === "TempSensorError" || type === "GasSensorError" || type === "ShockSensorError" || type === "SuddenRiseSensorError") return (<FontAwesomeIcon icon={faExclamationCircle} style={{color: "gray"}} />)
    else return (<UserOutlined />)
}

const AlertNotification = () => {
    let history = useHistory();
    const recentAlert = useSelector((state) => state.alert.recentAlert)

    useEffect(() => {
        if (recentAlert) {
            const notificationParam = {
                key: recentAlert.id,
                message: getAlertTitle(recentAlert),
                description: getAlertSubTitle(recentAlert),
                onClick: () => {
                    // TODO: 꼼수 ... 
                    const pathname = window.location.pathname;
                    if (pathname.startsWith(`${APP_PREFIX_PATH}/monitor`)) {
                        history.push(`${APP_PREFIX_PATH}/monitor`)
                        setTimeout(() => {
                            history.push(`${APP_PREFIX_PATH}/monitor/${recentAlert.manholeId}`)
                        }, 1000)
                    }
                    else {
                        history.push(`${APP_PREFIX_PATH}/monitor/${recentAlert.manholeId}`)
                    }
                    notification.close(recentAlert.id)
                },
                duration: 0,
                icon: getAlertIcon(recentAlert)
            }
        
            notification.error(notificationParam)
        }
        // eslint-disable-next-line 
    }, [recentAlert])

    return null;
}

export default AlertNotification
