import { 
    REQUEST_SMSNUMBER_SUCCESS, 
    REQUEST_SMSNUMBER_FAILED, 
    REQUEST_SMSNUMBER_ADD_SUCCESS, 
    REQUEST_SMSNUMBER_ADD_FAILED, 
    REQUEST_SMSNUMBER_REMOVE_SUCCESS, 
    REQUEST_SMSNUMBER_REMOVE_FAILED 
} from '../constants/SmsPhones'

import { message } from 'antd';

const initState = {
    data: [],
    err: null
}

const smsPhones = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_SMSNUMBER_SUCCESS: 
            return {
                ...state,
                data: action.data,
                err: null
            }
        case REQUEST_SMSNUMBER_FAILED: 
            return {
                ...state,
                data: [],
                err: action.error
            }
        case REQUEST_SMSNUMBER_ADD_SUCCESS:
            message.success('SMS 전송 번호 추가 성공')
            const number = [action.data]
            return {
                ...state,
                data: state.data.concat(number)
            }
        case REQUEST_SMSNUMBER_ADD_FAILED:
            message.error('SMS 전송 번호 추가 오류')
            return {
                ...state,
                err: action.error
            }
        case REQUEST_SMSNUMBER_REMOVE_SUCCESS:
            message.success('SMS 전송 번호 삭제 성공')
            return {
                ...state,
                data: state.data.filter((value, index, arr) => value.id !== action.data.id)
            }
        case REQUEST_SMSNUMBER_REMOVE_FAILED :
            message.error('SMS 전송 번호 삭제 오류')
            return {
                ...state,
                err: action.error
            }
        default:
            return state;
    }
}

export default smsPhones