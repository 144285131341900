import React from 'react'
import { Tooltip,Badge, Empty } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAmountUp, faSmog, faThermometerHalf, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faCreativeCommonsSampling } from '@fortawesome/free-brands-svg-icons'
import { useSelector } from "react-redux"
import TimeAgo from 'react-timeago'
import koreanStrings from 'react-timeago/lib/language-strings/ko'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import moment from 'moment'
import { useHistory } from "react-router-dom"

import { DATE_FORMAT_YYYY_MM_dd_HH_mm } from 'constants/DateConstant'
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const formatter = buildFormatter(koreanStrings)

function SideNavAlerts() {
  let history = useHistory()
  const ago24hr = moment().add(-1, "days").unix()


  const alerts = useSelector((state) => state.alert.data.filter((alert) => alert.occurrenceDate > ago24hr ))

  const getAlertTitle = (type) => {
    if (type === "SuddenRise") return "돌발수위"
    else if (type === "Shock") return "개폐감지"
    else if (type === "GasAlarm") return "가스감지"
    else if (type === "HighTemperature") return "온도상승"
    else if (type === "WaterSensorError" || type === "TempSensorError" || type === "GasSensorError" || type === "ShockSensorError" || type === "SuddenRiseSensorError") return "센서오류"
    else return ""
  }

  const getAlertSubTitle = (alert) => { 
    if (alert.type === "SuddenRise") return `현재수위 - ${alert.waterLevel}`
    else if (alert.type === "Shock") return "개폐 감지됨"
    else if (alert.type === "GasAlarm") return `가스레벨 - ${alert.gasLevel}`
    else if (alert.type === "HighTemperature") return `온도 - ${alert.temperature}`
    else if (alert.type === "WaterSensorError") return `수위 센서오류 - ${alert.waterLevel}`
    else if (alert.type === "TempSensorError") return `온도 센서오류 - ${alert.temperature}`
    else if (alert.type === "GasSensorError") return `가스 센서오류 - ${alert.gasLevel}`
    else if (alert.type === "ShockSensorError") return `개폐 센서오류`
    else if (alert.type === "SuddenRiseSensorError") return `돌발수위 센서오류`
    else return ""
  }

  const getAlertIcon = (type) => {
    if (type === "SuddenRise") return (<FontAwesomeIcon icon={faSortAmountUp} />)
    else if (type === "Shock") return (<FontAwesomeIcon icon={faCreativeCommonsSampling} />)
    else if (type === "GasAlarm") return (<FontAwesomeIcon icon={faSmog} />)
    else if (type === "HighTemperature") return (<FontAwesomeIcon icon={faThermometerHalf}/>)
    else if (type === "WaterSensorError" || type === "TempSensorError" || type === "GasSensorError" || type === "ShockSensorError" || type === "SuddenRiseSensorError") return (<FontAwesomeIcon icon={faExclamationCircle}/>)
    else return (<UserOutlined />)
  }

  const getAlertColor = (type) => {
    if (type === "SuddenRise") return "blue"
    else if (type === "Shock") return "green"
    else if (type === "GasAlarm") return "yellow"
    else if (type === "HighTemperature") return "red"
    else return "gray"
  }

  const clickAlert = (alert) => {
    // TODO: 꼼수 ... 
    const pathname = window.location.pathname;
    if (pathname.startsWith(`${APP_PREFIX_PATH}/monitor`)) {
        history.push(`${APP_PREFIX_PATH}/monitor`)
        setTimeout(() => {
            history.push(`${APP_PREFIX_PATH}/monitor/${alert.manholeId}`)
        }, 1000)
    }
    else {
        history.push(`${APP_PREFIX_PATH}/monitor/${alert.manholeId}`)
    }
  }

  return (
    <div>
      { alerts !== null && alerts.length > 0 ? 
        alerts.map((alert) => {
        return (
          <div key={alert.id} className="mt-3 ml-3 mr-3" style={{cursor: "pointer"}} onClick={() => clickAlert(alert)}>
            <div className={`d-flex align-items-center justify-content-between mb-4`}>
              <AvatarStatus  type={getAlertColor(alert.type)} icon={getAlertIcon(alert.type)} name={`#${alert.manholeSourceId} - ${getAlertTitle(alert.type)}`} subTitle={getAlertSubTitle(alert)} />
              <div className="text-right">
                <Tooltip title={moment.unix(alert.occurrenceDate).format(DATE_FORMAT_YYYY_MM_dd_HH_mm)}><div className="list-item-time"><TimeAgo date={moment.unix(alert.occurrenceDate)} formatter={formatter} /></div></Tooltip>
                {alert.done ? <Badge color="red" text="완료" /> : <></> }
              </div>
            </div>
          </div>
        )
      })
      : <Empty description={
            <span>
              알람 없음
            </span>
          }
        />}

    </div>
  )
}

export default SideNavAlerts
