import React from "react";
import moment from "moment"

class Clock extends React.Component{
	constructor() {
		super()
		this.state = {
			time: moment().format('LTS'),
            date: moment().format("YYYY[년] MM[월] DD[일]")
		}
	}

	componentDidMount() {
		setInterval(()=>{
			this.setState({
                time: moment().format('LTS'),
                date: moment().format("YYYY[년] MM[월] DD[일]")
            })
		},1000)
	}
	render() {
		return(
			<div id="clock">
                <h2 className="text-center mb-0">{this.state.time}</h2>
                <h5 className="text-center">{this.state.date}</h5>
			</div>
		)
	}
}

export default Clock