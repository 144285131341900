import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_MANHOLE_WEEKLY_DATA } from '../constants/Manhole'
import { requestManholeWeeklyDataLoading, requestManholeWeeklyDataSuccess, requestManholeWeeklyDataFailed} from '../actions/Manhole'
import ManholeService from '../../services/ManholeService'

export function* requestManholeWeeklyDataSaga() {
    yield takeEvery(REQUEST_MANHOLE_WEEKLY_DATA, function* (action) {
        try {
            yield put(requestManholeWeeklyDataLoading(true))
            const response = yield call(ManholeService.getManholeData, action.id, action.siteid)
            console.log('manhole weekly sagas: ', response)
            yield put(requestManholeWeeklyDataSuccess(response.data))
        } catch (err) {
            yield put(requestManholeWeeklyDataFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestManholeWeeklyDataSaga)
    ])
}