import {
    REQUEST_MANHOLE_WEEKLY_DATA_LOADING,
    REQUEST_MANHOLE_WEEKLY_DATA_SUCCESS,
    REQUEST_MANHOLE_WEEKLY_DATA_FAILED
} from '../constants/Manhole'

const initState = {
    weeklyData: [],
    loading: false,
    err: null
}

const manholes = (state = initState, action) => {
    switch (action.type) {
        
        case REQUEST_MANHOLE_WEEKLY_DATA_LOADING: 
        console.log("loading:", action)
            return {
                ...state,
                loading: action.isLoading
            }
        case REQUEST_MANHOLE_WEEKLY_DATA_SUCCESS: 

            if (state.weeklyData.some(e => e.id === action.data.id)) {
                // replace
                console.log("replace", action.data)
                return {
                    ...state,
                    err: null,
                    loading: false,
                    weeklyData: state.weeklyData.map((mdata) => mdata.id === action.data.id ? action.data : mdata)
                }
            } else {
                console.log("append", action.data)
                const newItem = [action.data]
                // append
                return {
                    ...state,
                    err: null,
                    loading: false,
                    weeklyData: newItem.concat(state.weeklyData)
                }
            }
            
        case REQUEST_MANHOLE_WEEKLY_DATA_FAILED: 
            return {
                ...state,
                loading: false,
                err: action.error
            }
        default: 
            return state;
    }
}

export default manholes