import {
    REQUEST_SMSNUMBER,
    REQUEST_SMSNUMBER_SUCCESS,
    REQUEST_SMSNUMBER_FAILED,
    REQUEST_SMSNUMBER_ADD,
    REQUEST_SMSNUMBER_ADD_SUCCESS,
    REQUEST_SMSNUMBER_ADD_FAILED,
    REQUEST_SMSNUMBER_REMOVE,
    REQUEST_SMSNUMBER_REMOVE_SUCCESS,
    REQUEST_SMSNUMBER_REMOVE_FAILED
} from '../constants/SmsPhones'

export const requestSmsNumbers = (siteid) => {
    return {
        type: REQUEST_SMSNUMBER,
        siteid
    }
}

export const requestSmsNumbersSuccess = (data) => {
    return {
        type: REQUEST_SMSNUMBER_SUCCESS,
        data
    }
}

export const requestSmsNumbersFailed = (error) => {
    return {
        type: REQUEST_SMSNUMBER_FAILED,
        error
    }
}

export const requestSmsNumbersAdd = (siteid, info) => {
    return {
        type: REQUEST_SMSNUMBER_ADD,
        siteid,
        info
    }
}

export const requestSmsNumbersAddSuccess = (data) => {
    return {
        type: REQUEST_SMSNUMBER_ADD_SUCCESS,
        data
    }
}

export const requestSmsNumbersAddFailed = (error) => {
    return {
        type: REQUEST_SMSNUMBER_ADD_FAILED,
        error
    }
}

export const requestSmsNumbersRemove = (id) => {
    return {
        type: REQUEST_SMSNUMBER_REMOVE,
        id
    }
}

export const requestSmsNumbersRemoveSuccess = (data) => {
    return {
        type: REQUEST_SMSNUMBER_REMOVE_SUCCESS,
        data
    }
}

export const requestSmsNumbersRemoveFailed = (error) => {
    return {
        type: REQUEST_SMSNUMBER_REMOVE_FAILED,
        error
    }
}
