export const REQUEST_SMSNUMBER = 'REQUEST_SMSNUMBER';
export const REQUEST_SMSNUMBER_SUCCESS = 'REQUEST_SMSNUMBER_SUCCESS'
export const REQUEST_SMSNUMBER_FAILED = 'REQUEST_SITES_FAILED'

export const REQUEST_SMSNUMBER_ADD = 'REQUEST_SMSNUMBER_ADD'
export const REQUEST_SMSNUMBER_ADD_SUCCESS = 'REQUEST_SMSNUMBER_ADD_SUCCESS'
export const REQUEST_SMSNUMBER_ADD_FAILED = 'REQUEST_SMSNUMBER_ADD_FAILED'

export const REQUEST_SMSNUMBER_REMOVE = 'REQUEST_SMSNUMBER_REMOVE'
export const REQUEST_SMSNUMBER_REMOVE_SUCCESS = 'REQUEST_SMSNUMBER_REMOVE_SUCCESS'
export const REQUEST_SMSNUMBER_REMOVE_FAILED = 'REQUEST_SMSNUMBER_REMOVE_FAILED'