import React from 'react'
import { APP_NAME } from 'configs/AppConfig';
import { CheckSquareOutlined, SyncOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";


export default function Footer() {
	const loading = useSelector((state) => state.localcenter.loading)
	const site = useSelector((state) => state.site.data)
	const signalrStatus = useSelector((state) => state.signalr.status)

	const statusToText = (status) => {
		if (status === 'connected') return '연결됨'
		else if (status === 'disconnected') return '연결끊김'
		else if (status === 'reconnecting') return '다시 연결중'
		else return ''
	}

	return (
		<footer className="footer">
			<span className={signalrStatus === 'disconnected' || signalrStatus === 'reconnecting' ? 'text-danger blink' : ''}><CheckSquareOutlined /> {site ? site.name : ''} 스마트맨홀360 시스템 - {statusToText(signalrStatus)}</span>	
			{loading ? <span><SyncOutlined spin /> {site ? site.name : ''} 데이터 가져오는 중 ...</span> : <></>}
			<span>&copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
		</footer>
	)
}