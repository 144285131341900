import { 
    REQUEST_MANHOLE_WEEKLY_DATA,
    REQUEST_MANHOLE_WEEKLY_DATA_LOADING,
    REQUEST_MANHOLE_WEEKLY_DATA_SUCCESS,
    REQUEST_MANHOLE_WEEKLY_DATA_FAILED
} from '../constants/Manhole'

export const requestManholeWeeklyData = (id, siteid) => {
    return {
        type: REQUEST_MANHOLE_WEEKLY_DATA,
        id,
        siteid
    }
}

export const requestManholeWeeklyDataLoading = (isLoading) => {
    return {
        type: REQUEST_MANHOLE_WEEKLY_DATA_LOADING,
        isLoading
    }
}

export const requestManholeWeeklyDataSuccess = (data) => {
    return {
        type: REQUEST_MANHOLE_WEEKLY_DATA_SUCCESS,
        data
    }
}

export const requestManholeWeeklyDataFailed = (error) => {
    return {
        type: REQUEST_MANHOLE_WEEKLY_DATA_FAILED,
        error
    }
}