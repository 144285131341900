import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SEND_PASSWORD_RESET_EMAIL
	// SIGNIN_WITH_GOOGLE,
	// SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	// signInWithGoogleAuthenticated,
	// signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signInEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				const result = yield call(FirebaseService.getIdTokenResult, user.user);
				console.log(user.user.toJSON())
				localStorage.setItem(AUTH_TOKEN, result.token);  // auth_token 에 uid 저장
				var userinfo = {
					id: user.user.uid,
					name: user.user.displayName,
					email: user.user.email,
					userType: result.claims["usertype"],
					siteId: result.claims["siteid"],
					siteName: result.claims["sitename"]
				}
				yield put(authenticated(userinfo));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				const result = yield call(FirebaseService.getIdTokenResult, user.user);
				console.log(user.user.toJSON())
				localStorage.setItem(AUTH_TOKEN, result.token);  // auth_token 에 uid 저장
				var userinfo = {
					id: user.user.uid,
					name: user.user.displayName,
					email: user.user.email,
					userType: result.claims["usertype"],
					siteId: result.claims["siteid"],
					siteName: result.claims["sitename"]
				}
				yield put(signUpSuccess(userinfo));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* sendPwdRstEmail() {
  yield takeEvery(SEND_PASSWORD_RESET_EMAIL, function* ({email}) {
		try {
			const result = yield call(FirebaseService.sendPasswordResetEmail, email);
			if (result) {
				yield put(showAuthMessage("이메일을 전송했습니다."));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

// export function* signInWithFBGoogle() {
//   yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
// 		try {
// 			const user = yield call(FirebaseService.signInGoogleRequest);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {


// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signInWithGoogleAuthenticated(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	});
// }

// export function* signInWithFacebook() {
//   yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
// 		try {
// 			const user = yield call(FirebaseService.signInFacebookRequest);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {


// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signInWithFacebookAuthenticated(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	});
// }

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(sendPwdRstEmail),
		// fork(signInWithFBGoogle),
		// fork(signInWithFacebook)
  ]);
}
