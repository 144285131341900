import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_DASHBOARD } from '../constants/Dashboard'
import { requestDashboardLoading, requestDashboardSuccess, requestDashboardFailed } from '../actions/Dashboard'
import ManholeService from '../../services/ManholeService'

export function* requestDashboard() {
    yield takeEvery(REQUEST_DASHBOARD, function* (action) {
        try {
            yield put(requestDashboardLoading(true))
            const response = yield call(ManholeService.getDashboard, action.siteid)
            yield put(requestDashboardSuccess(response.data))
        } catch (err) {
            yield put(requestDashboardFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestDashboard)
    ])
}