import fetch from 'auth/FetchInterceptor'

const ManholeService = {}

ManholeService.getSite = (siteid) => {
  return fetch({
    url: `/api/sites/${siteid}`,
    method: 'get'
  })
}

ManholeService.getLocalCenters = (siteid) => {
  return fetch({
    url: '/api/localcenters',
    method: 'get',
    params: {
      siteid: siteid
    }
  })
}

ManholeService.getAlerts = (siteid, start, end) => {
  return fetch({
    url: '/api/alerts',
    method: 'get',
    params: {
      siteid,
      start,
      end
    }
  })
}

ManholeService.getAllAlertsDone = (siteid, start, end) => {
  return fetch({
    url: '/api/alertsdone',
    method: 'put',
    params: {
      siteid,
      start,
      end
    }
  })
}

ManholeService.downloadAlerts = (siteid, start, end) => {
  return fetch({
    url: '/export/alert',
    method: 'get',
    responseType: 'blob',
    params: {
      siteid,
      start,
      end
    }
  })
}

ManholeService.putAlert = (alert) => {
  return fetch({
    url:`/api/alerts/${alert.id}`,
    method: 'put',
    data: alert
  })
}

ManholeService.putManhole = (manhole) => {
  return fetch({
    url:`/api/manholes/${manhole.id}`,
    method: 'put',
    data: manhole
  })
}

ManholeService.getDashboard = (siteid) => {
  return fetch({
    url: `/api/dashboard/${siteid}`,
    method: 'get'
  })
}


ManholeService.putLocalCenter = (localcenter) => {
  return fetch({
    url:`/api/localcenters/${localcenter.id}`,
    method: 'put',
    data: localcenter
  })
}

ManholeService.getManholeData = (id, siteid) => {
  return fetch({
    url: `/api/manholedata/${id}`,
    method: 'get',
    params: {
      siteid
    }
  })
}

ManholeService.deleteSmsPhoneNumber = (id) => {
  return fetch({
    url: `/api/smsphone/${id}`,
    method: 'delete'
  })
}

ManholeService.postSmsNumber = (siteid, smsPhoneNumber) => {
  return fetch({
    url: `/api/smsphone`,
    method: 'post',
    params: {
      siteid
    },
    data: smsPhoneNumber
  })
}

ManholeService.getSmsPhones= (siteid) => {
  return fetch({
    url: `/api/smsphone`,
    method: 'get',
    params: {
      siteid
    }
  })
}

export default ManholeService