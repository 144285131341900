import React from "react";
import { useSelector } from "react-redux";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  SettingOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import { useHistory } from "react-router-dom"
import IntlMessage from "../util-components/IntlMessage";
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { signOut } from 'redux/actions/Auth';

export const NavProfile = ({signOut}) => {
  let history = useHistory()
  const userinfo = useSelector((state) => state.auth.token)

  const handleResetPwd = () => {
    history.push(`${AUTH_PREFIX_PATH}/forgot-password`)
  }

  const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

  const profileImg = "/img/avatars/default.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{userinfo.name}</h4>
            <span className="text-muted">{userinfo.siteName} {userinfo.userType === 'User' ? '사용자' : '관리자'}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key="0" onClick={e => handleResetPwd()}>
            <span>
              <SettingOutlined className="mr-3"/>
              <span className="font-weight-normal">{setLocale(true, 'headnav.navprofile.menu.resetpassword')}</span>
            </span>
          </Menu.Item>
          <Menu.Item key="1" onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">{setLocale(true, 'headnav.navprofile.menu.signout')}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
