import React, { useState, useEffect } from 'react'
import { Row, Col, Tooltip } from 'antd';
import { useSelector } from "react-redux"

function ManholePieChart() {

  const centers = useSelector((state) => state.localcenter.data)
  const [nomalManholePercentage, setNomalManholePercentage] = useState(0)
  const [normalCnt, setNormalCnt] = useState(0)
  const [totalCnt, setTotalCnt] = useState(0)

  useEffect(() => {
    if (centers) {
      const manholes = centers.flatMap((elem) => elem.manholes)
      
      const tCnt = manholes.length
      const nCnt = manholes.reduce((cnt, cur,idx) => { 
        if (cur.waterLevelStatus === "Safe" && cur.shock === "Normal" && cur.suddenRise === "Normal" && cur.temperatureStatus === "Normal" && cur.gasAlarm === "Normal") cnt++
        return cnt
      }, 0);
      setNormalCnt(nCnt)
      setTotalCnt(tCnt)
      setNomalManholePercentage(Math.round(nCnt / tCnt * 100))
    }
    else setNomalManholePercentage(0)
    // eslint-disable-next-line
}, [centers])

  return (
    <Row gutter={16} className='mx-3'>
        <Col span={12}>
        <Tooltip title={`정상 ${normalCnt}개`}>
          <div style={{background: '#3e82f7', padding: '8px', textAlign: 'center', color: '#fff'}}>정상 {Number.isNaN(nomalManholePercentage) ? 0 : nomalManholePercentage}%</div>
          </Tooltip>
        </Col>
        <Col className="gutter-row" span={12}>
        <Tooltip title={`미처리 알람 ${totalCnt-normalCnt}개`}>
          <div style={{background: '#ff6b72', padding: '8px', textAlign: 'center', color: '#fff'}}>알람 {Number.isNaN(nomalManholePercentage) ? 0 : (100-nomalManholePercentage)}%</div>
          </Tooltip>
        </Col>
      </Row>
  )
}

export default ManholePieChart
