import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestSites } from 'redux/actions/Site'
import { requestAlerts } from 'redux/actions/Alerts'
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import AlertNotification from './alerts/AlertNotification'
import { setupSignalRConnection } from 'services/SignalRService';
import { waitRecentAlert } from 'redux/actions/Alerts'
import { waitManholeUpdate } from 'redux/actions/LocalCenter'

export const AppViews = () => {
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.auth.token)
  const site = useSelector((state) => state.site.data)

  useEffect(() => {
    dispatch(requestSites(userinfo.siteId))
    // get alerts
    if (site) dispatch(requestAlerts(site.id))
      // eslint-disable-next-line
  }, [])

  // connect signalR here... 
  useEffect(() => {
    // 한번만 호출되어야 하는데 ... 
    const connection = setupSignalRConnection()
    dispatch(waitRecentAlert(connection))
    dispatch(waitManholeUpdate(connection))
    // eslint-disable-next-line
  }, [])

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <AlertNotification />
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} exact component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/monitor`} exact component={lazy(() => import(`./monitor`))} />
        <Route path={`${APP_PREFIX_PATH}/monitor/:id`} exact component={lazy(() => import(`./monitor`))} />
        <Route path={`${APP_PREFIX_PATH}/alerts`} exact component={lazy(() => import(`./alerts`))} />
        <Route path={`${APP_PREFIX_PATH}/devices`} exact component={lazy(() => import(`./devices`))} />
        <Route path={`${APP_PREFIX_PATH}/device-detail/:id`} exact component={lazy(() => import(`./device-form`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/monitor`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);