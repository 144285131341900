import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';

import { REQUEST_ALERTS, REQUEST_ALERTS_MODIFY, WAIT_RECENT_ALERT, REQUEST_ALL_ALERTS_DONE } from '../constants/Alerts'
import { requestAlertsLoading, requestAlertsSuccess, requestAlertsFailed, addRecentAlert, requestAlertsModifySuccess, requestAlertsModifyFailed, requestAllAlertsDoneSuccess, requestAllAlertsDoneFailed } from '../actions/Alerts'
//import { updateAlertCount } from '../actions/Dashboard'
import ManholeService from '../../services/ManholeService'
import { createSignalRAlertChannel, closeChannel } from './createSignalRChannel';

export function* requestAlerts() {
    yield takeEvery(REQUEST_ALERTS, function* (action) {
        try {
            yield put(requestAlertsLoading(true))
            const response = yield call(ManholeService.getAlerts, action.siteid, action.start, action.end)
            yield put(requestAlertsSuccess(response.data))
        } catch (err) {
            yield put(requestAlertsFailed(err))
        }
    })
}

export function* requestAlertsModify() {
    yield takeEvery(REQUEST_ALERTS_MODIFY, function* (action) {
        try {
            const response = yield call(ManholeService.putAlert, action.alert)
            yield put(requestAlertsModifySuccess(response.data))
        } catch (err) {
            yield put(requestAlertsModifyFailed(err))
        }
    })
}

export function* waitRecentAlert() {
    yield takeEvery(WAIT_RECENT_ALERT, function* (action) {
        let channel
        try {
            channel = yield call(createSignalRAlertChannel, "receiveAlert", action.connection)

            while(true) {
                const alert = yield take(channel)
                yield put(addRecentAlert(alert))
                //yield put(updateAlertCount())
            }
        } catch(e) {
            console.log(e, "error")
        } finally {
            closeChannel(channel)
        }
    })
}

export function* requestAllAlertsDone() {
    yield takeEvery(REQUEST_ALL_ALERTS_DONE, function* (action) {
        try {
            yield put(requestAlertsLoading(true))
            const response = yield call(ManholeService.getAllAlertsDone, action.siteid, action.start, action.end)
            yield put(requestAllAlertsDoneSuccess(response.data))
        } catch (err) {
            yield put(requestAllAlertsDoneFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestAlerts),
        fork(requestAlertsModify),
        fork(waitRecentAlert),
        fork(requestAllAlertsDone)
    ])
}