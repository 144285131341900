import { all } from 'redux-saga/effects';
import Site from './Site'
import LocalCenter from './LocalCenter'
import Alert from './Alerts'
import Dashboard from './Dashboard'
import Manhole from './Manhole'
import SmsPhones from './SmsPhones'
import Auth from './Auth';

export default function* rootSaga(getState) {
  yield all([
    Site(),
    LocalCenter(),
    Alert(),
    Dashboard(),
    Manhole(),
    Auth(),
    SmsPhones()
  ]);
}
