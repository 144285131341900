import { eventChannel } from 'redux-saga'

export function createSignalRAlertChannel(eventType, connection) {
    return eventChannel(emit => {
        connection.on(eventType, message => {
            emit(message)
        })

        return () => {
            connection.stop()
        }
    })
}

export function createSignalRManholeUpdateChannel(eventType, connection) {
    return eventChannel(emit => {
        connection.on(eventType, message => {
            emit(message)
        })
        
        return () => {
            connection.stop()
        }
    })
}

export function createSignalRConnectionChannel(eventType, connection) {
    return eventChannel(emit => {
        connection.on(eventType, message => {
            // 메시지 받음
            emit(message)
        })

        return () => {
            connection.stop()
        }
    })
}

export function closeChannel(channel) {
    if (channel) {
        channel.close()
    }
}