import { 
    REQUEST_LOCALCENTER_LOADING, 
    REQUEST_LOCALCENTER_SUCCESS, 
    REQUEST_LOCALCENTER_FAILED, 
    REQUEST_LOCALCENTER_MODIFY_SUCCESS,
    REQUEST_LOCALCENTER_MODIFY_FAILED,
    REQUEST_MANHOLE_MODIFY_SUCCESS,
    REQUEST_MANHOLE_MODIFY_FAILED,
    UPDATE_CONNECTION_STATUS,
    UPDATE_MANHOLE } from '../constants/LocalCenter'

import { message } from 'antd';

const initState = {
    data: [],
    loading: false,
    err: null
}

const localcenters = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_LOCALCENTER_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_LOCALCENTER_SUCCESS: 
            return {
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_LOCALCENTER_FAILED: 
            return {
                data: [],
                loading: false,
                err: action.error
            }
        case REQUEST_LOCALCENTER_MODIFY_SUCCESS: 
            message.success('로컬센터 정보저장 성공')
            return {
                data: state.data.map((localcenter) => localcenter.id === action.localcenter.id ? action.localcenter : localcenter),
                loading: false,
                err: null
            }
        case REQUEST_LOCALCENTER_MODIFY_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case REQUEST_MANHOLE_MODIFY_SUCCESS: 
            message.success("맨홀 정보저장 성공")
            return {
                ...state,
                data: state.data.map((localcenter) => {
                    return {
                        ...localcenter,
                        manholes: localcenter.manholes.map( (manhole) => manhole.id === action.manhole.id ? action.manhole : manhole)
                    }
                })
            }
        case REQUEST_MANHOLE_MODIFY_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case UPDATE_CONNECTION_STATUS: 
            if (action.status.type === "localcenter") {
                return {
                    ...state,
                    data: state.data.map( (localcenter) => {
                        if (action.status.id === localcenter.id) { 
                            localcenter.connectionStatus = action.status.status
                            localcenter.updatedTime = action.status.updatedTime
                        }
                        return localcenter
                    })
                }
            } else {
                return state;
            }

        case UPDATE_MANHOLE: 
            return {
                ...state,
                data: state.data.map((localcenter) => {
                    return {
                        ...localcenter,
                        manholes: localcenter.manholes.map( (manhole) => manhole.id === action.status.id ? action.status : manhole)
                    }
                })
            }
        default:
            return state;
    }
}

export default localcenters