import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';

import { REQUEST_LOCALCENTER, REQUEST_LOCALCENTER_MODIFY, WAIT_MANHOLE_UPDATE, REQUEST_MANHOLE_MODIFY, WAIT_CONNECTION_STATUS } from '../constants/LocalCenter'
import { requestLocalCenterLoading, requestLocalCenterSuccess, requestLocalCenterFailed, updateManhole, requestManholeModifySuccess, requestManholeModifyFailed, requestLocalCenterModifySuccess, requestLocalCenterModifyFailed, updateConnectionStatus } from '../actions/LocalCenter'
import ManholeService from '../../services/ManholeService'
import { createSignalRManholeUpdateChannel, createSignalRConnectionChannel, closeChannel } from './createSignalRChannel';

export function* requestLocalCenter() {
    yield takeEvery(REQUEST_LOCALCENTER, function* (action) {
        try {
            yield put(requestLocalCenterLoading(true))
            const response = yield call(ManholeService.getLocalCenters, action.siteid)
            yield put(requestLocalCenterSuccess(response.data))
        } catch (err) {
            yield put(requestLocalCenterFailed(err))
        }
    })
}

export function* waitManholeUpdate() {
    yield takeEvery(WAIT_MANHOLE_UPDATE, function* (action) {
        let channel
        try { 
            channel = yield call(createSignalRManholeUpdateChannel, "receiveManholeUpdate", action.connection)

            while(true) {
                const status = yield take(channel)
                yield put(updateManhole(status))
            }
        } catch(e) {
            console.log(e, "error")
        } finally {
            closeChannel(channel)
        }
    })
}

export function* requestLocalCenterModify() {
    yield takeEvery(REQUEST_LOCALCENTER_MODIFY, function* (action) {
        try {
            const response = yield call(ManholeService.putLocalCenter, action.localcenter)
            yield put(requestLocalCenterModifySuccess(response.data))
        } catch (err) {
            yield put(requestLocalCenterModifyFailed(err))
        }
    })
}


export function* requestManholeModify() {
    yield takeEvery(REQUEST_MANHOLE_MODIFY, function* (action) {
        try {
            const response = yield call(ManholeService.putManhole, action.manhole)
            yield put(requestManholeModifySuccess(response.data))
        } catch (err) {
            yield put(requestManholeModifyFailed(err))
        }
    })
}

export function* waitConnectionUpdate() {
    yield takeEvery(WAIT_CONNECTION_STATUS, function* (action) {
        let channel
        try {
            channel = yield call(createSignalRConnectionChannel, "updateConnectionStatus", action.connection)

            while(true) {
                const status = yield take(channel)

                yield put(updateConnectionStatus(status))
            }
        } catch(e) {
            console.log(e, "error")
        } finally {
            closeChannel(channel)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestLocalCenter),
        fork(waitManholeUpdate),
        fork(requestLocalCenterModify),
        fork(waitConnectionUpdate),
        fork(requestManholeModify)
    ])
}